import diff from 'immutablediff'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import reportSchemeApi from 'api/ReportSchemeApi'

import {
  createReportSchemeError,
  createReportSchemeSuccess,
  copyReportSchemeSuccess,
  deleteReportSchemeError,
  deleteReportSchemeSuccess,
  getReportSchemes as getReportSchemesAction,
  getReportSchemesError,
  getReportSchemesSuccess,
  updateReportSchemeError,
  updateReportSchemeSuccess,
} from './actions'
import {
  CREATE_REPORT_SCHEME,
  DELETE_REPORT_SCHEME,
  GET_REPORT_SCHEMES,
  COPY_REPORT_SCHEME,
  UPDATE_REPORT_SCHEME,
} from './constants'

import { ReportSchemeRecord } from 'records'

// Individual exports for testing
export function* createReportScheme(action) {
  const {
    accountGroupType,
    accountSchemeTemplateId,
    accountSchemeId,
    companyCode,
    customerCode,
    description,
    hideAccountIntervals,
    makeTemplate,
    name,
    reportSchemeTemplateId,
    reportType,
    sourceSystems,
    balanceType,
  } = action
  try {
    const reportScheme = yield call(reportSchemeApi.createReportScheme, {
      accountGroupType,
      accountSchemeTemplateId,
      accountSchemeId,
      companyCode,
      customerCode,
      description,
      hideAccountIntervals,
      makeTemplate,
      name,
      reportSchemeTemplateId,
      reportType,
      sourceSystems,
      balanceType,
    })
    yield put(
      createReportSchemeSuccess({ companyCode, customerCode, reportScheme })
    )
  } catch (error) {
    yield put(
      handleError(error, createReportSchemeError, {
        error,
        companyCode,
        customerCode,
      })
    )
  }
}

export function* deleteReportScheme(action) {
  const { companyCode, customerCode, id } = action
  try {
    yield call(reportSchemeApi.deleteReportScheme, {
      companyCode,
      customerCode,
      id,
    })
    yield put(deleteReportSchemeSuccess({ companyCode, customerCode, id }))
  } catch (error) {
    yield put(
      handleError(error, deleteReportSchemeError, {
        error,
        companyCode,
        customerCode,
      })
    )
  }
}

export function* getReportSchemes(action) {
  const { companyCode, customerCode } = action
  try {
    const reportSchemes = yield call(reportSchemeApi.getReportSchemes, {
      companyCode,
      customerCode,
    })
    yield put(
      getReportSchemesSuccess({ companyCode, customerCode, reportSchemes })
    )
  } catch (error) {
    yield put(
      handleError(error, getReportSchemesError, {
        error,
        companyCode,
        customerCode,
      })
    )
  }
}

export function* copyReportScheme(action) {
  const {
    companyCode,
    customerCode,
    name,
    id,
    makeTemplate,
    toCustomerContext,
  } = action
  try {
    const reportScheme = yield call(reportSchemeApi.copyReportScheme, {
      companyCode,
      customerCode,
      name,
      id,
      makeTemplate,
    })
    yield put(
      copyReportSchemeSuccess({
        companyCode,
        customerCode,
        reportScheme,
      })
    )

    if (toCustomerContext) {
      yield put(getReportSchemesAction({ customerCode, companyCode: null }))
    }
  } catch (error) {
    yield put(
      handleError(error, createReportSchemeError, {
        error,
        companyCode,
        customerCode,
      })
    )
  }
}

export function* updateReportScheme(action) {
  const {
    customerCode,
    companyCode,
    oldReportScheme,
    newReportScheme,
    reportSchemeId,
  } = action
  try {
    const patch = diff(
      new ReportSchemeRecord({ ...oldReportScheme }),
      new ReportSchemeRecord({ ...newReportScheme })
    )
    const reportScheme = yield call(reportSchemeApi.patchReportScheme, {
      customerCode,
      companyCode,
      patch,
      reportSchemeId,
    })
    yield put(
      updateReportSchemeSuccess({ customerCode, companyCode, reportScheme })
    )
  } catch (error) {
    yield put(
      handleError(error, updateReportSchemeError, {
        error,
        companyCode,
        customerCode,
      })
    )
  }
}

// All sagas to be loaded
export function* reportSchemesSaga() {
  yield all([
    takeEvery(CREATE_REPORT_SCHEME, createReportScheme),
    takeEvery(COPY_REPORT_SCHEME, copyReportScheme),
    takeEvery(DELETE_REPORT_SCHEME, deleteReportScheme),
    takeEvery(GET_REPORT_SCHEMES, getReportSchemes),
    takeEvery(UPDATE_REPORT_SCHEME, updateReportScheme),
  ])
}

export default reportSchemesSaga
