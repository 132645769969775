/*
 *
 * ReportSchemes actions
 *
 */

import {
  CREATE_REPORT_SCHEME,
  CREATE_REPORT_SCHEME_ERROR,
  CREATE_REPORT_SCHEME_SUCCESS,
  COPY_REPORT_SCHEME,
  COPY_REPORT_SCHEME_ERROR,
  COPY_REPORT_SCHEME_SUCCESS,
  DELETE_REPORT_SCHEME,
  DELETE_REPORT_SCHEME_ERROR,
  DELETE_REPORT_SCHEME_SUCCESS,
  GET_REPORT_SCHEMES,
  GET_REPORT_SCHEMES_ERROR,
  GET_REPORT_SCHEMES_SUCCESS,
  TOGGLE_SHOW_CREATE_REPORT_SCHEME,
  UPDATE_REPORT_SCHEME,
  UPDATE_REPORT_SCHEME_ERROR,
  UPDATE_REPORT_SCHEME_SUCCESS,
} from './constants'

export const createReportScheme = ({
  accountGroupType,
  accountSchemeTemplateId,
  accountSchemeId,
  companyCode,
  customerCode,
  description,
  hideAccountIntervals,
  makeTemplate,
  name,
  reportSchemeTemplateId,
  reportType,
  sourceSystems,
  balanceType,
}) => ({
  type: CREATE_REPORT_SCHEME,
  accountGroupType,
  accountSchemeTemplateId,
  accountSchemeId,
  companyCode,
  customerCode,
  description,
  hideAccountIntervals,
  makeTemplate,
  name,
  reportSchemeTemplateId,
  reportType,
  sourceSystems,
  balanceType,
})
export const createReportSchemeError = ({
  error,
  companyCode,
  customerCode,
}) => ({
  companyCode,
  customerCode,
  type: CREATE_REPORT_SCHEME_ERROR,
  error,
})
export const createReportSchemeSuccess = ({
  companyCode,
  customerCode,
  reportScheme,
}) => ({
  type: CREATE_REPORT_SCHEME_SUCCESS,
  companyCode,
  customerCode,
  reportScheme,
})

export const copyReportScheme = ({
  companyCode,
  customerCode,
  name,
  toCustomerContext,
  id,
  makeTemplate,
}) => ({
  type: COPY_REPORT_SCHEME,
  companyCode,
  customerCode,
  toCustomerContext,
  name,
  id,
  makeTemplate,
})
export const copyReportSchemeError = ({
  error,
  companyCode,
  customerCode,
}) => ({
  companyCode,
  customerCode,
  type: COPY_REPORT_SCHEME_ERROR,
  error,
})
export const copyReportSchemeSuccess = ({
  companyCode,
  customerCode,
  reportScheme,
}) => ({
  type: COPY_REPORT_SCHEME_SUCCESS,
  companyCode,
  customerCode,
  reportScheme,
})

export const deleteReportScheme = ({ companyCode, customerCode, id }) => ({
  type: DELETE_REPORT_SCHEME,
  companyCode,
  customerCode,
  id,
})
export const deleteReportSchemeError = ({
  error,
  companyCode,
  customerCode,
}) => ({
  companyCode,
  customerCode,
  type: DELETE_REPORT_SCHEME_ERROR,
  error,
})
export const deleteReportSchemeSuccess = ({
  companyCode,
  customerCode,
  id,
}) => ({
  type: DELETE_REPORT_SCHEME_SUCCESS,
  companyCode,
  customerCode,
  id,
})

export const getReportSchemes = ({ companyCode, customerCode }) => ({
  type: GET_REPORT_SCHEMES,
  companyCode,
  customerCode,
})
export const getReportSchemesError = ({
  error,
  companyCode,
  customerCode,
}) => ({
  companyCode,
  customerCode,
  type: GET_REPORT_SCHEMES_ERROR,
  error,
})
export const getReportSchemesSuccess = ({
  companyCode,
  customerCode,
  reportSchemes,
}) => ({
  type: GET_REPORT_SCHEMES_SUCCESS,
  companyCode,
  customerCode,
  reportSchemes,
})

export const toggleShowCreateReportScheme = () => ({
  type: TOGGLE_SHOW_CREATE_REPORT_SCHEME,
})

export const updateReportScheme = ({
  customerCode,
  companyCode,
  oldReportScheme,
  newReportScheme,
  reportSchemeId,
}) => ({
  type: UPDATE_REPORT_SCHEME,
  customerCode,
  companyCode,
  oldReportScheme,
  newReportScheme,
  reportSchemeId,
})

export const updateReportSchemeError = ({
  error,
  companyCode,
  customerCode,
}) => ({
  companyCode,
  customerCode,
  type: UPDATE_REPORT_SCHEME_ERROR,
  error,
})

export const updateReportSchemeSuccess = ({
  customerCode,
  companyCode,
  reportScheme,
}) => ({
  type: UPDATE_REPORT_SCHEME_SUCCESS,
  customerCode,
  companyCode,
  reportScheme,
})
